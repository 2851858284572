/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  FilterAction,
  Wrap,
  WrapperLabel,
  SelectDatePicker,
  ButtonSearch,
  SelectItemLarge,
  BtnNewVersion,
  BtnExportExcel,
  ActionButton,
} from "./styled";
import { DatePicker, Form, Pagination, Select, Spin, Table, Tooltip } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { DownloadOutlined, LoadingOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatDateYYYYMMDD } from "Utils/convertValue";

import { ErrorField, ErrorMessage, TableContainer } from "Layouts/Admin/styled";
import moment from "moment";
import ModalDetailDataImport from "./ModalDetailDataImport/ModalDetailDataImport";
import ModalImport from "./ModalImport";
import { confirmImport, getLinkDownload, getListDataVersion, newVersion } from "Apis/admin";
import {
  IMPORT_DECIDED,
  IMPORT_ERROR,
  ImportStatus,
  ImportStatusClass,
  ImportVersion,
  ImportVersionClass,
  RECALL_API_TIME,
  STOP_CALL_API_TIME,
  TOOLTIP_ERROR_IMPORT,
  textNoData,
} from "Utils/data-default";
import { ERROR_API_MESSAGE, RESPONSE_STATUS } from "Constants";
import { Notification } from "Components";
import { useDispatch, useSelector } from "react-redux";
import actions from "Store/Actions";
import dayjs from "dayjs";
import LoadingModal from "Pages/LoadingModal";
import _ from 'lodash'
import ModalDownloadImport from "./ModalDownloadImport";
import axios from "axios";
import { saveAs } from 'file-saver';
import ModalExportExcel from "./ModalExportExcel";
import ModalVersionName from "./ModalVersionName";
import 'dayjs/locale/ja'
import locale from 'antd/es/date-picker/locale/ja_JP'
const { getVersionFilter, getVersionImport } = actions;
const ImportFlow = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [total, setTotal] = useState(50);
  const [listDataVersion, setListDataVersion] = useState();
  const [loaddingPage, setLoaddingPage] = useState(true);
  const [dataSearch, setDataSearch] = useState({});
  const [resetPage, setResetPage] = useState(1);
  const [openDetailModal, setOpenDetaiModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openVersionNameModal, setOpenVersionNameModal] = useState(false);
  const [openExportExcelModal,setOpenExportExcelModal] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [stepImport, setStepImport] = useState();
  const [version, setVersion] = useState();
  const [disable, setDisable] = useState();
  const dispatch = useDispatch();
  const [checkNewVersion, setCheckNewVersion] = useState();
  const { dataVersionFilter } = useSelector((state) => state.admin);
  const [loading, setLoading] = useState(false);
  const [reCallApi, setReCallApi] = useState(false);
  const [checkProcessing, setCheckProcessing] = useState(false);
  const [isOpen, setIsOpen] = useState({
    version: false,
    status: false,
  });
  useEffect(() => {
    async function fetchData() {
      let dataFilter = {};
      if (dataSearch) {
        for (let key in dataSearch) {
          if (
            dataSearch.hasOwnProperty(key) &&
            dataSearch[key] !== "" &&
            dataSearch[key] !== undefined
          ) {
            dataFilter[key] = dataSearch[key];
          }
        }
      }
      try {
        const data = await getListDataVersion({
          page: currentPage,
          size: sizePage,
          ...dataFilter,
        });
        handleDataResult(data.data, dataFilter); 
      } catch (error) {
        handleFetchDataError(error);
      }
    }
  
    fetchData();
  }, [sizePage, currentPage, resetPage]);
  
  function handleDataResult(result,dataFilter) {
    setTotal(result.total);
    setListDataVersion(result.items);
    const firstItem = { ...result?.items?.[0]};
    delete firstItem.version_number;
    if (result?.items && result?.items.length > 0) {
      setCheckProcessing(ImportVersionClass[firstItem?.version_status] === 'PROCESSING');
      setReCallApi(Object.values(firstItem).includes(5));
    }
    
    setSizePage(result.size);
    setCurrentPage(result.page);
    setLoaddingPage(false);
  
    if (!checkNewVersion) {
      setCheckNewVersion(firstItem?.version_status);
    }
  
    if (_.isEmpty(dataFilter) && ImportVersionClass[firstItem?.version_status] === 'APPROVED') {
      setCheckNewVersion(firstItem?.version_status);
    }
  }
  
  function handleFetchDataError(error) {
    Notification.error(
      ERROR_API_MESSAGE[error.message_code] || ERROR_API_MESSAGE[error.code] ||
        error.message ||
        error.errors?.[0].msg
    );
  }
  useEffect(() => {
    dispatch(
      getVersionFilter(
        {
          total: 1,
        },
        (action, res) => {}
      )
    );
    dispatch(getVersionImport({
      total: 0
    },(action, res) => {}));
  }, [dispatch, reCallApi, resetPage]);
  const getStatus = (status) => {
    const ListStatus = [
      "IN-PROGRESS",
      "PROCESSING",
      "APPROVED",
      "CANCELED",
      "IMPORT", 
      "IMPORT_DISABLE",
      "DONE",
      "IMPORTED",
      "APPROVE",
    ];
    return ListStatus.find((value) => value === status);
  };
  const disableButton = (value , statusVersion) => {
    return ImportStatusClass[value] === "IMPORT" || (ImportVersionClass[statusVersion] === "IN-PROGRESS" && ImportStatusClass[value] === "PROCESSING");
    
  };
  const disableImportAgain = (statusVersion, data) => ImportVersionClass[statusVersion] === "IN-PROGRESS" && Object.values(data).includes(5);
  const renderStatusCancel = (data) => {
    if (ImportVersionClass[data?.version_status] === "CANCELED") {
      return "キャンセル済";
    } else return "キャンセル";
  };
  const handleCancelImport = async (data) => {
    if (ImportVersionClass[data?.version_status] === "CANCELED") return;
    try {
      const dataApi = await confirmImport({
        type_confirm: 4,
        version_confirm: data?.version_number
      });
      const res = dataApi.data;
      if (res.status === RESPONSE_STATUS.SUCCESS) {
        setResetPage((pre) => pre + 1);
        setCheckNewVersion();
        setDisable(false);
      }
    } catch (error) {
      Notification.error(
        ERROR_API_MESSAGE[error.message_code] ||
          error.message ||
          error.errors?.[0].msg
      )
    }
  };
  const handleApproveImport = async (data) => {
    if (ImportVersionClass[data?.version_status] !== 'IN-PROGRESS' || ImportStatusClass[data?.import_seihoku_status] !== 'IMPORTED') return;
    setLoading(true);
    try {
      const dataApi = await confirmImport({
        type_confirm: 3,
        version_confirm: data?.version_number
      });
      const res = dataApi.data;
      if (res.status === RESPONSE_STATUS.SUCCESS) {
        Notification.success("対応中ですのでしばらくお待ちください。");  
        dispatch(
          getVersionImport(
            {
              total: 0,
            },
            (action, res) => {}
          )
        );
        setResetPage((pre) => pre + 1);
        setDisable(false);
      }
    } catch (error) {
      Notification.error(
        ERROR_API_MESSAGE[error.message_code] ||
          error.message ||
          error.errors?.[0].msg
      );
    }
    setLoading(false);
  };
  const renderErrorImport = (value) => {
    if (ImportVersionClass[value] === "IMPORT_ERROR") {
      return (
        <>
          <Tooltip title={TOOLTIP_ERROR_IMPORT} className="error_import">
            {IMPORT_ERROR}
          </Tooltip>
        </>
      );
    }
    else return IMPORT_DECIDED
  }
  const renderDateImport = (value, statusImport) => {
    if (ImportStatusClass[statusImport] === "PROCESSING") {
      return (
        <>
          <Spin indicator={antIcon} />
        </>
      );
    }
    else return formatDateYYYYMMDD(value)
  }
  useEffect(() => {
    let timeoutId
    if (reCallApi) {
      timeoutId = setTimeout(() => {
          resetData();
      }, RECALL_API_TIME)
    }
    return () => clearTimeout(timeoutId);
  }, [resetPage, reCallApi])
  useEffect(() => {
    let timeoutId;
  
    if (checkProcessing) {
      timeoutId = setTimeout(() => {
        if (reCallApi) {
          setReCallApi(false);
        }
      }, STOP_CALL_API_TIME );
    }
  
    return () => {
      clearTimeout(timeoutId);
    };
  }, [checkProcessing]);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const columns = [
    {
      title: "バージョン",
      dataIndex: "version_number",
      key: "version_number",
      className: "version_number data-step",
      render: (version, record) => (
        <Tooltip
          title={`${version}${record.version_name ? ` - ${record.version_name.replace(/\s/g, '\u00A0')}` : ''}`}
        >
             <span className={ (ImportVersionClass[record?.version_status] === "IN-PROGRESS" && ImportStatusClass[record?.import_course_status] === "IMPORTED") ? "id_record": ""} onClick={() => handleShowDetail(record)}>
                {version}{record.version_name ? ` - ${record.version_name.replace(/\s/g, '\u00A0')}` : ''}
              </span>
        </Tooltip>
        
      ),
    },
    {
      title: "ステータス",
      dataIndex: "version_status",
      key: "version_status",
      className: "status-import data-step",
      render: (value) => (
        <div className={getStatus(ImportVersionClass[value]) + " status"}>
          {ImportVersion[value]}
        </div>
      ),
    },
    {
      title: (
        <span>
          ステップ①
          <br /> 講座関連情報
        </span>
      ),
      className: "step-import",
      children: [
        {
          title: "インポート日付",
          dataIndex: "import_course_date",
          key: "import_course_date",
          render: (import_course_date, record) => (
            <span>
              {(import_course_date && ImportVersionClass[record?.import_course_status] !== 'IMPORT_ERROR' ) ||   ImportStatusClass[record?.import_course_status] === 'PROCESSING' 
                ? renderDateImport(import_course_date, record?.import_course_status)  : renderErrorImport(record?.import_course_status)}
            </span>
          ),
        },
        {
          title: "ステータス",
          dataIndex: "import_course_status",
          key: "import_course_status",
          className: "status-import data-step",
          render: (value, record) => (
            <button
              className={
                getStatus(ImportStatusClass[value]) +
                " status" +
                (getStatus(ImportStatusClass[value]) === "IMPORT" ||
                getStatus(ImportStatusClass[value]) === "IMPORTED"
                  ? " status-btn"
                  : " ")
              }
              onClick={() => handleShowImport(record, "step1", value)}
            >
              {ImportStatus[value]}
            </button>
          ),
        },
      ],
    },
    {
      title: (
        <span>
          ステップ②
          <br /> 講座セット
        </span>
      ),
      className: "step-import",
      children: [
        {
          title: "インポート日付",
          dataIndex: "import_set_course_date",
          key: "import_set_course_date",
          render: (import_set_course_date,record) => (
            <span>
              {(import_set_course_date && ImportVersionClass[record?.import_set_course_status] !== 'IMPORT_ERROR') ||   ImportStatusClass[record?.import_set_course_status] === 'PROCESSING'
                ? renderDateImport(import_set_course_date, record?.import_set_course_status)
                : renderErrorImport(record?.import_set_course_status)}
            </span>
          ),
        },
        {
          title: "ステータス",
          dataIndex: "import_set_course_status",
          key: "import_set_course_status",
          className: "status-import data-step",
          render: (value, record) => (
            <button
              disabled={
                disableImportAgain(record.version_status,{
                  import_course_status: record.import_course_status,
                }) ||
                disableButton(record.import_course_status , record?.version_status) ||
                (ImportVersionClass[record?.version_status] === "CANCELED" &&
                  ImportStatusClass[value] === "IMPORT")
              }
              className={
                getStatus(ImportStatusClass[value]) +
                " status" +
                (getStatus(ImportStatusClass[value]) === "IMPORT" ||
                getStatus(ImportStatusClass[value]) === "IMPORTED"
                  ? " status-btn"
                  : " ")
              }
              onClick={() => handleShowImport(record, "step2", value)}
            >
              {ImportStatus[value]}
            </button>
          ),
        },
      ],
    },
    {
      title: (
        <span>
          ステップ③ <br /> 科目別配点用
        </span>
      ),
      className: "step-import",
      children: [
        {
          title: "インポート日付",
          dataIndex: "import_seihoku_date",
          key: "import_seihoku_date",
          render: (import_seihoku_date,record) => (
            <span>
              {(import_seihoku_date && ImportVersionClass[record?.import_seihoku_status] !== 'IMPORT_ERROR') ||   ImportStatusClass[record?.import_seihoku_status] === 'PROCESSING'
                ? renderDateImport(import_seihoku_date, record?.import_seihoku_status)
                : renderErrorImport(record?.import_seihoku_status)}
            </span>
          ),
        },
        {
          title: "ステータス",
          dataIndex: "import_seihoku_status",
          key: "import_seihoku_status",
          className: "status-import data-step",
          render: (value, record) => (
            <button
              disabled={
                disableImportAgain(record.version_status,{
                  import_course_status: record.import_course_status,
                  import_set_course_status: record.import_set_course_status,
                  // import_school_group_status: record.import_school_group_status,
                }) ||
                disableButton(record.import_set_course_status, record?.version_status)
                // || (ImportVersionClass[record?.version_status] === "CANCELED" &&
                //   ImportStatusClass[value] === "IMPORT")
              }
              className={
                getStatus(ImportStatusClass[value]) +
                " status" +
                (getStatus(ImportStatusClass[value]) === "IMPORT" ||
                getStatus(ImportStatusClass[value]) === "IMPORTED"
                  ? " status-btn"
                  : " ")
              }
              onClick={() => handleShowImport(record, "step3", value)}
            >
              {ImportStatus[value]}
            </button>
          ),
        },
      ],
    },
    {
      title: (
        <span>
          ステップ④
          <br /> グループ関連情報
        </span>
      ),
      className: "step-import",
      children: [
        {
          title: "インポート日付",
          dataIndex: "import_school_group_date",
          key: "import_school_group_date",
          render: (import_school_group_date, record) => (
            <span>
              {(import_school_group_date && ImportVersionClass[record?.import_school_group_status] !== 'IMPORT_ERROR') ||   ImportStatusClass[record?.import_school_group_status] === 'PROCESSING'
                ? renderDateImport(import_school_group_date, record?.import_school_group_status)
                : renderErrorImport(record?.import_school_group_status)}
            </span>
          ),
        },
        {
          title: "ステータス",
          dataIndex: "import_school_group_status",
          key: "import_school_group_status",
          className: "status-import data-step",
          render: (value, record) => (
            <button
              disabled={
                disableImportAgain(record.version_status,{
                  import_course_status: record.import_course_status,
                  import_set_course_status: record.import_set_course_status,
                  import_seihoku_status: record.import_seihoku_status,
                }) ||
                disableButton(record.import_seihoku_status, record?.version_status) 
                // || (ImportVersionClass[record?.version_status] === "CANCELED" &&
                //   ImportStatusClass[value] === "IMPORT")
              }
              className={
                getStatus(ImportStatusClass[value]) +
                " status" +
                (getStatus(ImportStatusClass[value]) === "IMPORT" ||
                getStatus(ImportStatusClass[value]) === "IMPORTED"
                  ? " status-btn"
                  : " ")
              }
              onClick={() => handleShowImport(record, "step4", value)}
            >
              {ImportStatus[value]}
            </button>
          ),
        },
      ],
    },
    {
      title: (
        <span>
          ステップ⑤ <br /> 最終認証
        </span>
      ),
      className: "step-import",
      children: [
        {
          title: "認証日付",
          dataIndex: "update_at",
          key: "update_at",
          className: "status-import",
          render: (update_at, record) =>
            ImportVersionClass[record?.version_status] === "APPROVED" ||
            ImportVersionClass[record?.version_status] === "IN-PROGRESS" ||
            ImportVersionClass[record?.version_status] === "PROCESSING" ? (
              <span>{update_at ? formatDateYYYYMMDD(update_at) : "未定"}</span>
            ) : (
              <button className="status"></button>
            ),
        },
        {
          title: "ステータス",
          dataIndex: "version_status",
          key: "version_status",
          render: (version_status, record) =>
            ImportVersionClass[record?.version_status] !== "CANCELED" ? (
              <button
                disabled={
                  disableImportAgain(record.version_status,{
                    import_course_status: record.import_course_status,
                    import_set_course_status: record.import_set_course_status,
                    import_school_group_status: record.import_school_group_status,
                    import_seihoku_status: record.import_seihoku_status
                  }) ||
                  disableButton(record.import_seihoku_status, record?.version_status) || ImportVersionClass[record?.version_status] === "PROCESSING"}
                className={
                  ImportVersionClass[record?.version_status] !== "APPROVED"
                    ? "APPROVED status status-btn"
                    : "APPROVED status"
                }
                onClick={() => handleApproveImport(record)}
              >
                {ImportVersionClass[record?.version_status] !== "APPROVED"
                  ? "認証"
                  : "認証済"}
              </button>
            ) : (
              <button className="status"></button>
            ),
        },
        {
          title: "キャンセル日付",
          dataIndex: "update_at",
          key: "update_at",
          render: (update_at, record) =>
            ImportVersionClass[record?.version_status] !== "APPROVED" ? (
              <span>{update_at ? formatDateYYYYMMDD(update_at) : "未定"}</span>
            ) : (
              <button className="status"></button>
            ),
        },
        {
          title: "ステータス",
          dataIndex: "version_status",
          key: "version_status",
          className: "data-step",
          render: (value, record) => (
            <div className="data-status">
              {ImportVersionClass[record?.version_status] !== "APPROVED" ? (
                <button
                  disabled= {ImportVersionClass[record?.version_status] === "PROCESSING"}
                  className={
                    ImportVersionClass[record?.version_status] === "CANCELED"
                      ? "CANCEL status"
                      : "CANCEL status status-btn"
                  }
                  onClick={() => handleCancelImport(record)}
                >
                  {renderStatusCancel(record)}
                </button>
              ) : (
                <button className="status"></button>
              )}
            </div>
          ),
        },
      ],
    },
  ];

  const validationSchema = yup.object({
    version: yup.number(),
    status: yup.number(),
    approve_from: yup
      .string()
      .test(
        "check-import-to",
        "開始日は終了日よりの前に日付を選択してください",
        function (value) {
          const fromDate = moment(value, "DD/MM/YYYY");
          const toDate = moment(this.parent.approve_to, "DD/MM/YYYY");
          return fromDate.isSameOrBefore(toDate) || !this.parent.approve_to || !value;
        }
      ),
    approve_to: yup.string(),
  });
  const form = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setSizePage(pageSize);
  };
  const dataSource =
    listDataVersion &&
    [...listDataVersion]
      .sort((a, b) => b.version_number - a.version_number)
      .map((item, index) => ({
        ...item,
        key: index,
      }));
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = form;
  const onSubmit = async (dataFilter) => {
    setDataSearch(dataFilter);
    setCurrentPage(1);
    setResetPage((pre) => pre + 1);
  };
  const optionsStatus = [
    { label: "インポート中", value: 1 },
    { label: "認証済", value: 3 },
    { label: "キャンセル済", value: 4 },
  ];
  const handleSelectDateFrom = (date, dateString) => {
    trigger("approve_from");
    trigger("approve_to");
    setValue(
      "approve_from",
      dateString ? dayjs(dateString).format("DD/MM/YYYY") : dateString
    );
  };
  const handleSelectDateTo = (date, dateString) => {
    trigger("approve_from");
    trigger("approve_to");
    setValue(
      "approve_to",
      dateString ? dayjs(dateString).format("DD/MM/YYYY") : dateString
    );
  };
  const handleShowDetail = async (data) => {
    if (
      (ImportVersionClass[data?.version_status] === "IN-PROGRESS" && ImportStatusClass[data?.import_course_status] === "IMPORTED")
    ) {
      setOpenDetaiModal(true);
    }
    else  return;
  };
  const handleShowImport = async (data, step, status) => {
    if (data?.version_status !== 1) {
      return;
    }
    switch (step) {
      case "step1":
        if (
          ImportStatusClass[data.import_course_status] !== "IMPORT" &&
          ImportStatusClass[status] !== "IMPORTED"
        )
          return;
        break;
      case "step2":
        if (
          ImportStatusClass[data.import_course_status] !== "IMPORTED" &&
          ImportStatusClass[status] !== "IMPORTED"
        )
          return;
        break;
      case "step3":
        if (
          ImportStatusClass[data.import_set_course_status] !== "IMPORTED" &&
          ImportStatusClass[status] !== "IMPORTED"
        )
          return;
        break;
      case "step4":
        if (
          ImportStatusClass[data.import_seihoku_status] !== "IMPORTED" &&
          ImportStatusClass[status] !== "IMPORTED"
        )
          return;
        break;
      default:
        return;
    }
    if (step === 'step4') {
      setVersion(data?.version_number);
      setStepImport(step);
      setOpenDownloadModal(true)
      return
    }
    setVersion(data?.version_number);
    setStepImport(step);
    setOpenImportModal(true);
  };
  const handleNewVersion = async (version_name) => {
    try {
      setDisable(true);
      const data = await newVersion({version_name: version_name});
      const res = data.data;
      if (res.status === RESPONSE_STATUS.SUCCESS) {
        setCheckNewVersion();
        setCurrentPage(1);
        setResetPage((pre) => pre + 1);
      }
    } catch (error) {
      setDisable(false);
      Notification.error(
        ERROR_API_MESSAGE[error.message_code] ||
          error.message ||
          error.errors?.[0].msg
      );
    }

    setOpenVersionNameModal(false)
  };
  const resetData = () => {
    setResetPage((pre) => pre + 1);
  };
  const handleCancel = () => {
    setLoading(false);
  };
  const handleSelectKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };
  const handleClickDownload = async () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      handleDownload()
    },2000)
  }
  const handleDownload = async () => {
    try {
      const data = await getLinkDownload();
      const link = data.data.base_response.data
      downloadFile(link)
    } catch (error) {
      handleFetchDataError(error);
    }
  }
  const downloadFile = (link) => {
    const url = link;
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob'
    })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'グループ関連情報.xlsx');
        setOpenDownloadModal(false)
        setOpenImportModal(true)
      })
      .catch(error => {
        handleFetchDataError(error);
      });
  }
  
  return (
    <Wrap>
      <FormProvider {...form}>
        <form
          id="information-form"
          autoComplete="off"
          // onSubmit={handleSubmit(onSubmit)}
        >
          <ActionButton>
            <BtnNewVersion
              disabled={
                (checkNewVersion &&
                  (ImportVersionClass[checkNewVersion] === "IN-PROGRESS" ||
                    ImportVersionClass[checkNewVersion] === "PROCESSING")) ||
                disable
              }
              onClick={()=>setOpenVersionNameModal(true)}
            >
              <PlusOutlined />
              新バージョン作成
            </BtnNewVersion>
            <BtnExportExcel onClick={() => setOpenExportExcelModal(true)}> 
              <DownloadOutlined />
              最新版ダウンロード
            </BtnExportExcel>
          </ActionButton>
          <FilterAction>
            <SelectItemLarge>
              <Form.Item label={<WrapperLabel>バージョン</WrapperLabel>}>
                <Select
                  className="select_type"
                  name="version"
                  showSearch
                  options={dataVersionFilter}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) => (String(option?.label ?? "") ).includes(input)}
                  onChange={(e) => setValue("version", e)}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        version: false,
                      }));
                    }
                    handleSelectKeyDown(e)
                  }}
                  open={isOpen.version}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      version: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItemLarge>
            <SelectItemLarge>
              <Form.Item label={<WrapperLabel>ステータス</WrapperLabel>}>
                <Select
                  className="select_type"
                  name="status"
                  showSearch
                  options={optionsStatus}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  onChange={(e) => setValue("status", e)}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        status: false,
                      }));
                    }
                    handleSelectKeyDown(e)
                  }}
                  open={isOpen.status}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      status: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItemLarge>
            <SelectDatePicker>
              <Form.Item
                label={<WrapperLabel>インポート日付から</WrapperLabel>}
                validateStatus={errors.approve_from ? "error" : ""}
              >
                <DatePicker
						      locale={locale}
                  name="approve_from"
                  allowClear={true}
                  onChange={handleSelectDateFrom}
                  inputReadOnly={true}
                  placeholder="日付を選択してください"
                  getPopupContainer={() =>
                    document.getElementById("dropdown-year")
                  }
                />
              </Form.Item>
              <ErrorField>
                <ErrorMessage>{errors?.approve_from?.message}</ErrorMessage>
              </ErrorField>
            </SelectDatePicker>
            <SelectDatePicker>
              <Form.Item
                label={<WrapperLabel>インポート日付まで</WrapperLabel>}
                validateStatus={errors.approve_from ? "error" : ""}
              >
                <DatePicker
                  locale={locale}
                  name="approve_to"
                  allowClear={true}
                  onChange={handleSelectDateTo}
                  inputReadOnly={true}
                  placeholder="日付を選択してください"
                  getPopupContainer={() =>
                    document.getElementById("dropdown-year")
                  }
                />
              </Form.Item>
            </SelectDatePicker>
            <ButtonSearch htmlType="submit" onClick={handleSubmit(onSubmit)}>
              <SearchOutlined />
              検索
            </ButtonSearch>
          </FilterAction>
        </form>
      </FormProvider>
      <TableContainer>
        <Table
          columns={columns}
          loading={loaddingPage}
          dataSource={dataSource ?? []}
          pagination={false}
          scroll={{ y: 440, x: true }}
          className="table__flow--import"
          locale={{
            emptyText: textNoData,
          }}
        />
        {dataSource && dataSource.length > 0 && (
          <Pagination
            style={{ marginTop: "16px", textAlign: "right" }}
            total={total}
            current={currentPage}
            onChange={handlePageChange}
            showSizeChanger={true}
            pageSize={sizePage}
            locale={{
              items_per_page: "/ ページ",
            }}
          />
        )}
      </TableContainer>
      {openDetailModal && (
        <ModalDetailDataImport
          visibleModal={openDetailModal}
          onCancel={() => setOpenDetaiModal(false)}
          onOk={() => setOpenDetaiModal(false)}
        />
      )}
      {openImportModal && (
        <ModalImport
          visibleModal={openImportModal}
          onCancel={() => setOpenImportModal(false)}
          onOk={() => setOpenImportModal(false)}
          version={version}
          stepImport={stepImport}
          resetData={resetData}
        />
      )}
      { 
        openDownloadModal && 
        <ModalDownloadImport
          visibleModal={openDownloadModal}
          onCancel={() => {
            setOpenDownloadModal(false)
            setOpenImportModal(true)
          }}
          onOk={handleClickDownload}
        />}
      {openExportExcelModal && <ModalExportExcel
        visibleModal={openExportExcelModal}
        onCancel={() => setOpenExportExcelModal(false)}
        onOk={() => setOpenExportExcelModal(false)}
      />}
      {openVersionNameModal && <ModalVersionName
        visibleModal={openVersionNameModal}
        onCancel={() => setOpenVersionNameModal(false)}
        onOk={handleNewVersion}
      />}
      {loading && <LoadingModal textLoading={""} cancelEvent={handleCancel} />}
    </Wrap>
  );
};

export default ImportFlow;
