import { Button } from "antd";
import styled from "styled-components";

export const Wrap = styled.div`
  .ant-form-item {
    height: max-content;
    width: 100%;
    margin-bottom: 0px;

    .ant-select {
      min-height: 40px !important;
      border-radius: 5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      .ant-select-selector {
        min-height: 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000 !important;

        .ant-select-selection-search-input {
          height: 100%;
        }

        .ant-select-selection-item {
          display: flex;
          align-items: center;
        }
      }
    }
    .ant-select-clear {
      display: flex !important;
    }

    .ant-form-item-row {
      display: flex;
      flex-direction: column;
    }

    .ant-form-item-label {
      text-align: start;
    }

    .ant-select-disabled {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000 !important;
    }

    .select__menu {
      z-index: 10;
    }

    .select__control {
      border: ${({ validateStatus, theme }) =>
        validateStatus === "error"
          ? `1px solid ${theme.error_ant} !important`
          : ""};
      box-shadow: ${({ validateStatus }) =>
        validateStatus === "error" ? "none" : ""};
    }

    .ant-form-item-children-icon {
      display: none;
    }

    .select__dropdown-indicator {
      color: hsl(0, 0%, 60%);
    }

    label::after {
      content: "" !important;
    }
    .ant-table-wrapper {
      table {
        th {
          border-left: 2px solid black !important;
        }
      }
    }
  }
  .ant-table-placeholder {
    .ant-table-cell {
      border-right: 2px solid black;
      border-left: 2px solid black;
    }
  }
  .ant-table-container {
    border-bottom: 2px solid black;
  }
  .ant-table-wrapper .ant-table .ant-table-cell {
    text-align: center;
    padding: 0px;
    height: 42px;
    vertical-align: middle;
  }
  .data-status {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: 100%;
    width: 100%;
    font-weight: bold;
    padding: 0 6px;
    white-space: nowrap;
    border: none;
    background-color: #afaeae;
    cursor: default;
    &.status-btn {
      color: white;
      cursor: pointer;
      margin: 6px 10px;
      width: calc(100% - 20px);
      height: calc(100% - 12px);
      border-radius: 5px;
      box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
        rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      &.IMPORTED {
        background-color: #509750;
      }
      &.CANCEL {
        background-color: #ef6464;
      }
      &.APPROVED {
      background-color: #1677ff;
    }
    }
    :disabled {
      cursor: not-allowed;
      color: white;
      background-color: #878484 !important;
    }
    &.IN-PROGRESS {
      background-color: #ffff7c;
    }
    &.PROCESSING {
      background-color: #ffff7c;
    }
    &.APPROVED {
      background-color: #89d389;
      :disabled {
        cursor: not-allowed;
        color: white;
        background-color: #878484;
      }
    }
    &.DONE {
      background-color: #89d389;
    }
    &.IMPORTED {
      background-color: #89d389;
    }
    &.CANCELED {
      background-color: #f59f9f;
    }
    &.CANCEL {
      background-color: #f59f9f;
    }
    &.IMPORT {
      background-color: #1677ff;
      :disabled {
        background-color: #878484;
        color: white;
      }
    }
    &.IMPORT_DISABLE {
      background-color: #d9d9d9;
    }
    &.APPROVE {
      background-color: #d9d9d9;
    }
  }
  .version_number{
    white-space: nowrap;
    padding: 10px !important;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const FilterAction = styled.div`
  display: flex;
  gap: 0 15px;
  align-items: end;
  max-width: 1300px;
  @media only screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;
export const SelectItemLarge = styled.div`
  max-width: 260px;
  width: 100%;
`;
export const SelectItem = styled.div`
  max-width: 200px;
  width: 100%;
`;
export const ItemInput = styled.div`
  max-width: 400px;
  width: 100%;
`;
export const SelectDatePicker = styled.div`
  max-width: 200px;
  width: 100%;
  .ant-picker {
    min-height: 40px !important;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
  }
`;
export const WrapperLabel = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 30px;
  color: #6d737a;
`;
export const ButtonSearch = styled(Button)`
  height: 40px;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #ffffff;
  font-family: "Public Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: rgb(22, 119, 255);
  margin-top: 15px;
`;

export const BtnNewVersion = styled(Button)`
  height: 40px;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #ffffff;
  font-family: "Public Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: rgb(22, 119, 255);
`;
export const BtnExportExcel = styled(Button)`
  height: 40px;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #ffffff;
  font-family: "Public Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: rgb(22, 119, 255);
  margin-left: 20px;
`;

export const ActionButton = styled.div`
  display:flex
`